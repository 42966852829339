import React, { Component } from 'react'
import { Image } from 'semantic-ui-react'
import {RULE_FILTER_DYNAMIC_TYPES_BY_ID, RULE_FILTER_TYPE_CODES} from '../../../constant.ts'

class HiddenIcon extends Component {

    /*
       * Helpers
       */
      hiddenEyeIcon() {
        var hoverOverText = "Specialty Filter: Condition hidden from provider"
        return <Image title= { hoverOverText }
            src="/asset/image/hidden-icon.png"
          className="addressed-icon" />
      }

      renderStandAloneHiddenIcon(){
        if(this.conditionShouldHaveHiddenIcon(this.props.condition)){
          return this.hiddenEyeIcon()
        }
        return null
      }

      renderGroupHiddenIcon(){
        if(this.props.conditions.every((condition) => { return this.conditionShouldHaveHiddenIcon(condition) })) {
            return this.hiddenEyeIcon()
        }
        return null
      }

      conditionShouldHaveHiddenIcon(condition){
        return condition.statusCode === 'HIDDEN'
          && (condition.conditionStatus === 'KNOWN' || condition.conditionStatus === 'POTENTIAL')
          && RULE_FILTER_DYNAMIC_TYPES_BY_ID[condition.ruleFilterId] === RULE_FILTER_TYPE_CODES.SPECIALTY
      }

      render() {
          if (this.props.condition) {
            return this.renderStandAloneHiddenIcon(this.props.condition)
          } if (this.props.conditions) {
            return this.renderGroupHiddenIcon(this.props.conditions)
          }
          return null
        }
}

export default HiddenIcon