/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { Accordion, Grid } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'
// Local
import { ROLES } from '../../../constant'
import {
  conditionClaimSource,
  condition as conditionUtils
} from '../../common/conditionUtils'
import { formatConditionTitle, displayRiskCode } from '../../common/formatUtils'
import AddressedIcon from '../common/addressedIcon'
import HiddenIcon from '../common/hiddenIcon'
import ConditionAddressedDate from '../common/conditionAddressedDate'
import ConditionDismissed from '../common/conditionDismissed'
import ConditionNotes from '../common/ConditionNotes'
import ConditionReasons from '../common/conditionReasons'
import CdiPotentialConditionTagsRead from './cdiPotentialConditionTagsRead'
import { EncounterConditionDisplay } from '../../authenticated/types'
import If from '../../common/if'
import NLPExtractedLink from './nlpExtractedLink'

/**
 * Provides a component that renders a CDI condition read-only view
 * @param onSubmitNote Callback for condition note submit
 *        Arguments: condition, noteContent, noteReason
 * @param condition Condition entity
 * @param isActive If true, display accordion open. Default: false
 * @param hccChild True if condition is a member of an HCC display group
 * @param displayIndex Condition display index relative to its display group.
 *        Used to make style decisions
 * @param onClickTitle Callback for condition accordion title click
 *        Arguments: condition
 */

type Props = {
  onSubmitNote: any
  condition: EncounterConditionDisplay
  isActive: boolean
  hccChild: boolean
  displayIndex: number
  onClickTitle: any
}

function CdiConditionRead(props: Props): JSX.Element {
  const { onSubmitNote, condition, isActive, hccChild, displayIndex, onClickTitle } =
    props

  const onSubmitNoteLocal = (
    c: EncounterConditionDisplay,
    noteBody: any,
    noteReason: any
  ) => {
    onSubmitNote(c, noteBody, noteReason)
  }

  const claimSource = conditionClaimSource(condition)
  const hccCode = condition.hccCode
    ? `(${displayRiskCode(condition.hccCode)})`
    : ''
  const titleText = formatConditionTitle(condition)
  const clazz =
    displayIndex && displayIndex === 0
      ? 'flex-title first-accordion'
      : 'flex-title'
  const containerId = `cdi-encounter-condition-read-${condition.id}`
  const titleId = `cdi-encounter-condition-read-title-${condition.id}`
  const contentId = `cdi-encounter-condition-read-body-${condition.id}`

  return (
    <>
      <div id={containerId} className="condition">
        <Accordion.Title
          active={ isActive }
          index={ condition.id }
          onClick={ () => {
            if ( !hccChild ) onClickTitle(condition)
          } }
          className={ clazz }
        >
          <AddressedIcon condition={ condition } />
          <HiddenIcon id="hidden-icon-standalone" condition={ condition } />
          <div id={titleId} className="condition-title">
            { isActive ? (
              <strong>{ `${hccCode} ${titleText}` }</strong>
            ) : (
              <span>
                <strong>{ hccCode }</strong> { titleText }
              </span>
            ) }
          </div>
          <div className="condition-toolbar">
            <ConditionDismissed condition={ condition } />
          </div>
        </Accordion.Title>
        <Accordion.Content active={ isActive }>
          <div className="ui stackable divided one column grid internal-condition">
            <Grid.Row className="first-row">
              <Grid.Column>
                <div id={contentId}>
                  <ConditionReasons condition={ condition } />
                  <ReactMarkdown
                    children={ condition.content as string }
                    linkTarget="_blank"
                  />
                  <ConditionAddressedDate condition={ condition } />
                  <ConditionAddressedDate condition={ condition } />
                  <NLPExtractedLink condition={ condition } />
                </div>
              </Grid.Column>
            </Grid.Row>
            <If isTrue={ !!claimSource }>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <span className="footnote">
                    Initial source of condition: { claimSource }
                  </span>
                </Grid.Column>
              </Grid.Row>
            </If>
            { conditionUtils.isPotential(condition) &&
              conditionUtils.hasCdiTags(condition) && (
              <Grid.Row className="divider">
                <Grid.Column textAlign="center">
                  <CdiPotentialConditionTagsRead condition={ condition } />
                </Grid.Column>
              </Grid.Row>
            ) }
            <Grid.Row className="divider">
              <Grid.Column>
                <ConditionNotes
                  condition={ condition }
                  userRole={ ROLES.CDI }
                  onSubmit={ onSubmitNoteLocal }
                />
              </Grid.Column>
            </Grid.Row>
          </div>
        </Accordion.Content>
      </div>
    </>
  )
}

export default CdiConditionRead
